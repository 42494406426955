import React, {
    Fragment,
    useState
  } from 'react'  
import update from 'immutability-helper'
import {
    Card,
    TextField,
    InlineError,
    FormLayout,
  } from "@shopify/polaris"

function ProductTab(props) {
    const { shop, state, setState, productPickerShow, error } = props;
    
    return (
              <FormLayout>
                
                  <TextField 
                    label="Offer Product Title" 
                    placeholder="Breath Mints" 
                    onChange={(val) => setState(update(state, { title: { $set: val }})) }
                    value={state.title}
                  />
                  <TextField 
                    label="Offer Product Subtitle" 
                    placeholder="Add on mints for 15% off!" 
                    onChange={(val) => setState(update(state, { subtitle: { $set: val }})) }
                    value={state.subtitle}
                  />
                  <TextField 
                    label="Marketing message" 
                    placeholder="Get this exclusive deal now!" 
                    onChange={(val) => setState(update(state, { message: { $set: val }})) }
                    value={state.message}
                    helpText={
                      <span>
                        This message will introduce the offer to the customer
                      </span>
                    }
                  />
              </FormLayout>
    )
}

export default ProductTab;